<template>
  <span>
    <template v-if="value === 'W'">
      Wodny
    </template>
    <template v-else-if="value === 'WM'">
      <span>Wodny z opcją elektryczną</span>
    </template>
    <template v-else-if="value === 'E'">
      Elektryczny
    </template>
    <template v-else-if="value === 'M'">
      Wodno-elektryczny
    </template>
    <template v-else-if="value === 'Yes'">
      Tak
    </template>
    <template v-else-if="value === 'No'">
      Nie
    </template>
    <template v-else-if="label === 'free_valves' && value === '1'">
      Tak
    </template>
    <template v-else-if="label === 'free_valves' && value === 'Nie został wybrany'">
      Nie
    </template>
    <template v-else>
      {{ value|htmlDecode }}
    </template>
  </span>
</template>

<script>
import ProductAttribute from '../../ProductAttribute.vue'

export default {
  mixins: [ProductAttribute]
}
</script>
