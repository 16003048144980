<template>
  <div class="compare bg-cl-secondary">
    <div class="paddings">
      <div class="container">
        <breadcrumbs class="bg-cl-secondary" :with-homepage="true" :routes="[]" active-route="Porównanie Towarów" />
      </div>
    </div>
    <div class="container visible-xs" v-if="items.length">
      <div class="row m0">
        <div class="col-xs-12 uppercase cl-alternative p0 py15 fs-medium">
          Masz {{ items.length }} Towary w porównaniu
        </div>
      </div>
    </div>
    <div class="pl20-desktop">
      <div class="container" v-if="items.length">
        <div class="row m0 flex-nowrap scroll-x">
          <div class="col-xs-12 p0">
            <div class="row flex-nowrap m0">
              <div class="col-xs-5 col-md-3 h4 p0">
                <div class="mx5 bg-cl-white cl-matterhorn custom-shadow pt20 pl15 h-100">
                  Dane techniczne
                </div>
              </div>
              <div v-for="(product, index) in items" :key="index + 'tile'" class="col-xs-6 col-md-4 p0"
                   data-testid="comparedProduct"
              >
                <product-tile :is-comparable-tile="true" :link-active="isProductSelected(product)" :product="product" />
              </div>
            </div>
            <div class="row m0 mt20 flex-nowrap">
              <div class="col-xs-5 col-md-3 p0">
                <div class="bg-cl-white">
                  <ul class="compare__features-list cl-mine-black fs-medium-small">
                    <li v-for="(attr, index) in all_comparable_attributes" :key="index" class="compare__features-item brdr-1 flex middle-xs">
                      {{ $t(attr.default_frontend_label) }}
                    </li>
                    <li class="compare__features-item brdr-1 flex middle-xs">
                      SKU
                    </li>
                    <li class="compare__features-item brdr-1 flex middle-xs">
                      {{ $t("width_filter") }}
                    </li>
                    <li class="compare__features-item brdr-1 flex middle-xs">
                      {{ $t("height_filter") }}
                    </li>
                    <li class="compare__features-item brdr-1 flex middle-xs">
                      {{ $t("Power") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-6 col-md-4 p0" v-for="(product, index) in items" :key="index + 'attr'">
                <div class="bg-cl-white">
                  <ul class="compare__features-list cl-mine-black fs-medium-small">
                    <li
                      v-for="(attr, attIndex) in all_comparable_attributes"
                      :key="attIndex"
                      :class="{ 'active' : !isSame(attr.attribute_code) && showDiff }"
                      class="compare__features-item brdr-1 align-right flex middle-xs end-xs"
                    >
                      <product-attribute
                        :key="attr.attribute_code"
                        :product="product"
                        :attribute="attr"
                        empty-placeholder="Nie został wybrany"
                      />
                      {{ addAttribute(attr.attribute_code, product[attr.attribute_code]) }}
                    </li>
                    <li class="compare__features-item brdr-1 align-right flex middle-xs end-xs">
                      {{ isProductSelected(product) ? product.sku : product.parentSku }}
                    </li>
                    <li class="compare__features-item brdr-1 align-right flex middle-xs end-xs">
                      {{ product.width ? getLabelforValue('width', product.width) : 'Nie został wybrany' }}
                    </li>
                    <li class="compare__features-item brdr-1 align-right flex middle-xs end-xs">
                      {{ product.height ? getLabelforValue('height', product.height) : 'Nie został wybrany' }}
                    </li>
                    <li class="compare__features-item brdr-1 align-right flex middle-xs end-xs">
                      {{ isProductSelected(product) ? getLabelforValue('power_75_65_20', product.power_75_65_20) : getMinMaxValue(product, 'power_75_65_20') }} (75/65/20)<br>
                      {{ isProductSelected(product) ? getLabelforValue('power_70_55_20', product.power_70_55_20) : getMinMaxValue(product, 'power_70_55_20') }} (70/55/20)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row m0">
              <div class="col-xs-12 flex p0">
                <div @click="toggleDiff()" class="pointer uppercase cl-primary-orange brdr-1 brdr-cl-primary-orange py10 px20 bg-cl-white mt10 border-rad">
                  {{ showDiff ? 'UKRYJ RÓŻNICE' : 'POKAŻ RÓŻNICE' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="row">
          <div class="col-xs-12">
            <h4 class="cl-accent ml30">
              {{ $t('You have no items to compare.') }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Compare from '@vue-storefront/core/pages/Compare'
import Breadcrumbs from '../components/core/Breadcrumbs'
import ProductTile from '../components/core/ProductTile'
import ProductAttribute from '../components/core/blocks/Compare/ProductAttribute'
import i18n from '@vue-storefront/i18n'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  components: {
    Breadcrumbs,
    ProductTile,
    ProductAttribute
  },
  data () {
    return {
      allAttributes: {},
      showDiff: false
    }
  },
  mixins: [Compare, getLabelMixin],
  props: {
    title: {
      type: String,
      required: true
    }
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  methods: {
    isProductSelected (product) {
      return product.hasOwnProperty('width') || product.hasOwnProperty('height')
    },
    getMinMaxValue (product, type) {
      if (product.configurable_children && product.configurable_children.length) {
        let allValues = product.configurable_children
          .map(prod => Number(this.getLabelforValue(type, prod[type])))
          .filter(num => num > 0)
          .sort((a, b) => a - b)
        if (allValues && allValues.length) {
          return (allValues.length === 1 && allValues[0])
            ? allValues[0] + 'W'
            : allValues[0] + ' - ' + allValues[allValues.length - 1]
        } else {
          return null
        }
      }
    },
    toggleDiff () {
      this.showDiff = !this.showDiff
    },
    addAttribute (type, value) {
      if (this.allAttributes[type]) {
        this.allAttributes[type].push(value)
      } else {
        this.allAttributes[type] = []
      }
    },
    isSame (type) {
      if (this.allAttributes[type] && this.showDiff) {
        return this.allAttributes[type].every(v => v === this.allAttributes[type][0])
      } else {
        return false
      }
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || this.title || i18n.t('Compare Products'),
      meta: this.$route.meta.description ? [{ vmid: 'description', description: this.$route.meta.description }] : []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-white: color(white);
$border: mix(#000, $color-white, 10%);
$color-product-bg: color(secondary, $colors-background);

$products-column-width-mobile: 140px;
$products-column-width: 280px;
$features-column-width-mobile: 110px;
$features-column-width: 210px;

$screen-l: 1170px;
.border-rad {
  border-radius: 20px;
}
.pl20-desktop {
  @media (min-width: 767px) {
    padding-left: 20px;
  }
}
.paddings {
  @media (min-width: 767px) {
    padding: 15px 0 15px 20px;
  }
}
.scroll-x {
  overflow-x: auto;
  scroll-behavior: smooth;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.custom-shadow {
  box-shadow: 0px 3px 6px #00000029;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.compare {

  &__products-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__products-columns {
    margin: 0 0 0 $features-column-width-mobile;
    padding: 0;
    display: flex;

    @media (min-width: $screen-l) {
      margin-left: $features-column-width;
    }
  }

  &__products-table {
    position: relative;
    overflow: hidden;
  }

  &__features {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: $features-column-width-mobile;
    border-style: solid;
    border-color: $border;
    border-width: 1px 0 1px 0;
    background-color: mix(#000, $color-white, 2%);
    opacity: .95;

    @media (min-width: $screen-l) {
      width: $features-column-width;
    }
  }

  &__product {
    list-style: none;
    position: relative;
    float: left;
    width: $products-column-width-mobile;
    text-align: center;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    @media (min-width: $screen-l) {
      width: $products-column-width;
    }
  }

  &__attributes {
    line-height: 14px;
    padding: 15px 5px;
    @media (max-width: 767px) {
      padding: 15px 10px;
    }
    @media (min-width: $screen-l) {
      padding: 25px 10px;
      line-height: 16px;
    }
  }

  &__top-info {
    position: relative;
    height: 250px;
    width: $products-column-width-mobile;
    text-align: center;
    border-color: $border;
    border-style: solid;
    border-width: 0 1px 0 0;
    transition: height 0.3s;
    cursor: pointer;
    background: $color-product-bg;
    overflow: hidden;

    @media (min-width: $screen-l) {
      height: 385px;
      width: $products-column-width;
    }

    .compare__features & {
      @extend .compare__attributes;
      width: $features-column-width-mobile;
      cursor: auto;
      background: mix(#000, $color-white, 2%);

      @media (min-width: $screen-l) {
        width: $features-column-width;
      }
    }
  }

  &__features-list {
    margin: 0;
    padding: 0;
  }

  &__features-item {
    &.active {
      background-color:#ffe9d2;
    }
    @extend .compare__attributes;
    list-style: none;
    border-color: #D9D9D9;
    height: 70px;
    @media (max-width: 767px) {
      height: 60px;
    }
    .compare__products-columns & {
      font-weight: normal;
      text-align: center;
    }
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
  }

}
.h-100 {
  height: 100%;
}
</style>
